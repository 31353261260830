import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar, useQuery, NetworkStatus } from '@apollo/client';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

import { Box, BoxFlex, Button, Card, Loader, Typography } from '@tucar/pit-stop';
import { IconButton } from '@mui/material';
import { getAuthStore } from '../../../graphql/store';
import GET_DOCUMENTS from '../../../graphql/querys/docs/getDocuments';
import UploadFileButton from '../../../components/UploadFileButton';
import translateDocsName from '../../../helpers/translateDocsName';
import ErrorModal from '../../../components/ui/ErrorModal';

const DocsForm = ({ goNext }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    cedulaIdentidadFront: false,
    cedulaIdentidadBack: false,
    licenciaConducirFront: false,
    licenciaConducirBack: false,
    certificadoAntecedentes: false,
    hojaVida: false,
  });

  const handleChange = (name, event) => {
    setValues((currentValues) => ({
      ...currentValues,
      [name]: event,
    }));
  };

  const {
    data: docsData,
    loading,
    networkStatus,
  } = useQuery(GET_DOCUMENTS.query, { variables: { uid }, ...GET_DOCUMENTS.policies });

  useEffect(() => {
    if (!loading && docsData?.getDocument) {
      const auxData = docsData.getDocument.files.map((elem, ident) => {
        const auxObj = { ...elem };
        // eslint-disable-next-line no-param-reassign
        auxObj.fileType = 'application/pdf, image/*';
        auxObj.loadingRefetch = false;
        auxObj.id = ident;
        return auxObj;
      });
      auxData.map((elem) => handleChange(elem.name, elem));
    }
  }, [docsData, loading, networkStatus]);

  if (loading || networkStatus === NetworkStatus.refetch) {
    return (
      <BoxFlex style={{ paddingTop: '30px', justifyContent: 'center' }}>
        <Loader size={32} pointRadio="4px" />
      </BoxFlex>
    );
  }

  return (
    <BoxFlex flexDirection="column" gap="16px" sx={{ width: '100%' }}>
      {Object.entries(values).filter((elem) => elem.status === 'Nothing').length > 0 ? (
        <Typography
          variant="p"
          style={{
            color: '#0057B8',
            fontSize: '14px',
            fontWeight: 500,
            textAlign: 'left',
            lineHeight: 1.2,
            paddingInline: '30px',
          }}
        >
          Debes subir tus documentos de conductor para continuar con el proceso de registro.
        </Typography>
      ) : (
        <Card style={{ padding: '20px', marginInline: '30px' }}>
          <Typography
            variant="p"
            style={{
              color: '#0057B8',
              fontSize: '14px',
              fontWeight: 500,
              textAlign: 'left',
              lineHeight: 1.2,
            }}
          >
            Gracias por enviarnos tus documentos. Los revisaremos en la próxima etapa, durante la
            reunión presencial.
          </Typography>
          <BoxFlex alignContent="center" gap="20px" style={{ marginTop: '20px' }}>
            <Typography
              variant="p"
              style={{
                color: '#0057B8',
                fontSize: '14px',
                fontWeight: 500,
                textAlign: 'left',
                lineHeight: 1.2,
              }}
            >
              Contáctanos llamando a un ejecutivo
            </Typography>

            <IconButton
              href="tel:+56232103489"
              sx={{
                backgroundColor: '#c1d5e9',
                '&:hover': {
                  backgroundColor: '#c1d5e9',
                },
              }}
            >
              <LocalPhoneOutlinedIcon sx={{ color: '#185db7' }} />
            </IconButton>
          </BoxFlex>
        </Card>
      )}
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat"
        title="Error al subir archivo"
      />
      {Object.entries(values).map(([name, file]) => {
        return (
          <UploadFileButton
            key={name}
            url={file?.url}
            status={file?.status}
            label={translateDocsName(name)}
            fileType={file?.fileType}
            fileName={name}
            handler={handleChange}
            openError={() => setOpen(true)}
          />
        );
      })}
      <Box style={{ paddingTop: '20px', alignSelf: 'center' }}>
        <Button onClick={goNext} disabled={Object.values(values).some((value) => !value)}>
          Siguiente
        </Button>
      </Box>
    </BoxFlex>
  );
};

export default DocsForm;

DocsForm.propTypes = {
  goNext: PropTypes.func,
};

DocsForm.defaultProps = {
  goNext: () => {},
};
