import React from 'react';
import { Typography, BoxFlex } from '@tucar/pit-stop';

const HeaderDocs = () => {
  return (
    <BoxFlex flexDirection="column" gap="20px" style={{ alignItems: 'start' }}>
      <Typography
        style={{
          color: '#0057B8',
          fontSize: '28px',
          fontWeight: 600,
          letterSpacing: '0.56',
          lineHeight: 1,
        }}
      >
        Carga de archivos
      </Typography>
    </BoxFlex>
  );
};

export default HeaderDocs;
