import React, { useState, useEffect } from 'react';
import { useReactiveVar, useMutation } from '@apollo/client';
import { Box, BoxFlex, Typography } from '@tucar/pit-stop';
import PropTypes from 'prop-types';

import BankAccountForm from '../../../components/ui/BankAccountForm';
import { getAuthStore, getDriverStore } from '../../../graphql/store';
import ADD_BANK from '../../../graphql/mutations/driver/addBank';
import ErrorModal from '../../../components/ui/ErrorModal';
import NewAccountingInfo from '../../appV2/profile/Accounting';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';

const OnBoardingBankAccount = ({ goBack }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [addBank, { loading, data, error }] = useMutation(ADD_BANK.mutation);
  const [open, setOpen] = useState(false);

  const {
    verifiedList: { bank },
  } = useReactiveVar(getDriverStore);

  const [values, setValues] = useState({
    uid,
    rol: 'Driver',
    bank: '',
    accountType: '',
    account: '',
    name: '',
    rut: '',
    email: '',
  });

  const handleSend = () => {
    setValues((currentValues) => ({
      ...currentValues,
      account: values.account.split(' ').join('').trim(),
    }));
    addBank({ variables: values }).catch(() => {});
  };

  useEffect(() => {
    if (!loading) {
      if (data) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
      if (error) {
        setOpen(true);
      }
    }
  }, [loading]);

  if (bank) {
    return (
      <Box
        style={{
          paddingBottom: '60px',
          minHeight: '100vh',
          width: '100vw',
          background: '#F0F0F3',
        }}
      >
        <NewAccountingInfo gInfo onBoarding goBack={goBack} />
      </Box>
    );
  }

  return (
    <Box
      style={{
        height: '100vh',
        width: '100vw',
        background: '#F0F0F3',
      }}
    >
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat."
        title="Error al enviar información"
        emojiSymbol="🤔"
      />
      <BoxFlex flexDirection="column" style={{ padding: '0px 0px' }}>
        <TopNav2 title="" prevPage="/app/onboarding" handlePageChange={goBack} />
        <Typography
          style={{
            paddingInline: '30px',
            fontSize: '28px',
            fontWeight: 600,
            letterSpacing: 0.56,
            color: '#0057B8',
            paddingBottom: '15px',
          }}
        >
          Ingresa tu cuenta de depósito
        </Typography>
        <Typography
          style={{
            paddingInline: '30px',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.5,
            letterSpacing: 0.28,
            color: '#0057B8',
            paddingBottom: '63px',
          }}
        >
          Una vez completados tus datos, podrás acceder fácilmente a todos los beneficios y
          servicios que ofrecemos.
        </Typography>
      </BoxFlex>
      <Box style={{ paddingInline: '30px', paddingBottom: '60px' }}>
        <BankAccountForm values={values} setValues={setValues} handleSend={handleSend} />
      </Box>
    </Box>
  );
};

OnBoardingBankAccount.propTypes = {
  goBack: PropTypes.func,
};

OnBoardingBankAccount.defaultProps = {
  goBack: () => {},
};

export default OnBoardingBankAccount;
