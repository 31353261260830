import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Typography, IconButton } from '@mui/material';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import { Card, Button, TucarTheme, Icon } from '@tucar/pit-stop';
import { getAuthStore, getDriverStore } from '../../../../graphql/store';
import ProfilePhoto from '../../../../../public/images/profileImage.svg';
import GET_DRIVER_PHOTO from '../../../../graphql/querys/driver/getPhoto';
import translateUberPro from '../../../../helpers/translateUberPro';
import CategoryIcon from '../../../../components/ui/categoryIcon';
import LogOut from '../../../../components/auth/LogOutButton';
import WomanDriverImg from '../../../../../public/images/woman-driver.jpeg';
import useGoToProfileApp from '../../../../hooks/useGoToProfileApp';

const GeneralProfile = ({ setMessageError, setOpenError, setCurrentTab }) => {
  const [handlePageChange] = useOutletContext();
  const { goToProfileApp } = useGoToProfileApp();

  useEffect(() => {
    handlePageChange(null, true);
  }, []);

  const {
    data: { uid, firstname, lastname },
  } = useReactiveVar(getAuthStore);
  const { photo, category = {} } = useReactiveVar(getDriverStore);
  const [photoDriver, setPhotoDriver] = useState(photo);
  const [getDriverPhoto, { data, loading }] = useLazyQuery(
    GET_DRIVER_PHOTO.query,
    GET_DRIVER_PHOTO.policies,
  );

  useEffect(() => {
    if (data) {
      setPhotoDriver(data.getDriver.photo);
    }
  }, [loading, data]);

  const handleUpload = (file) => {
    const dataUp = new FormData();
    dataUp.append('files', file);
    dataUp.append('id', uid);
    const config = {
      method: 'post',
      url: `${process.env.DOCUMENT_GATEWAY_URL}/api/v1/cl/driver/attach-photo`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Apikey ${process.env.DOCUMENT_GATEWAY_API_KEY}`,
      },
      data: dataUp,
    };
    axios(config)
      .then(async () => {
        await getDriverPhoto({ variables: { uid } });
      })
      .catch(() => {
        setMessageError(
          'Ups, a ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat',
        );
        setOpenError(true);
      });
  };

  return (
    <Box sx={{ paddingInline: '20px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
      <Card id="general-profile-photo" style={{ padding: '10px 10px', backgroundColor: '#FFF' }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
            <IconButton type="submit" component="label">
              <input
                hidden
                accept="image/*"
                onChange={(e) => {
                  handleUpload(e.target.files[0]);
                }}
                type="file"
              />
              <EditIcon
                sx={{
                  borderRadius: '55px',
                  backgroundColor: '#FFF',
                  color: TucarTheme.colors.blue500,
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              transform: 'translateY(-20px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                background: 'linear-gradient(140deg, #0057B8, #50CAFF)',
                padding: '0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '167px',
                height: '167px',
              }}
            >
              <img
                src={photoDriver || ProfilePhoto}
                alt="profilePhoto"
                style={{
                  objectFit: 'cover',
                  height: '160px',
                  width: '160px',
                  borderRadius: '50%',
                  border: '3px solid #FFF',
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            transform: 'translateY(-10px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography sx={{ lineHeight: 1 }} fontFamily="Poppins-Medium">
            {firstname} {lastname}
          </Typography>
          <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <CategoryIcon category={category?.uberPro} />
            <Typography sx={{ fontSize: '14px', color: '#5B5D71' }} fontFamily="Poppins-Regular">
              {translateUberPro(category?.uberPro)}
            </Typography>
          </Box>
        </Box>

        <Box>
          <LogOut />
        </Box>
      </Card>

      <Box>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px', color: '#585D71' }}>
          General
        </Typography>
        <Box
          sx={{
            paddingInline: '20px',
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Button
            fullWidth
            size="medium"
            startAdornment={<Icon name="settingsOutlined" color={TucarTheme.colors.blue500} />}
            endAdornment={
              <Box sx={{ transform: 'rotate(180deg)' }}>
                <Icon name="leftArrowSpecial" size="16px" color="#A8AABA" />
              </Box>
            }
            onClick={goToProfileApp}
            contentStyle={{ lineHeight: 1.2 }}
          >
            Configuración de cuenta
          </Button>
          <Button
            fullWidth
            size="medium"
            startAdornment={<PersonIcon sx={{ color: TucarTheme.colors.blue500 }} />}
            endAdornment={
              <Box sx={{ transform: 'rotate(180deg)' }}>
                <Icon name="leftArrowSpecial" size="16px" color="#A8AABA" />
              </Box>
            }
            onClick={() => setCurrentTab('personal')}
          >
            Datos Personales
          </Button>

          <Button
            fullWidth
            size="medium"
            startAdornment={
              <Icon name="attachFileOutlined" size="24px" color={`${TucarTheme.colors.blue500}`} />
            }
            endAdornment={
              <Box sx={{ transform: 'rotate(180deg)' }}>
                <Icon name="leftArrowSpecial" size="16px" color="#A8AABA" />
              </Box>
            }
            onClick={() => setCurrentTab('documents')}
          >
            Documentos
          </Button>

          <Button
            fullWidth
            size="medium"
            startAdornment={
              <Icon name="paymentOutlined" size="24px" color={`${TucarTheme.colors.blue500}`} />
            }
            endAdornment={
              <Box sx={{ transform: 'rotate(180deg)' }}>
                <Icon name="leftArrowSpecial" size="16px" color="#A8AABA" />
              </Box>
            }
            onClick={() => setCurrentTab('accounting')}
          >
            Cuenta bancaria
          </Button>
        </Box>
      </Box>

      <Box>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px', color: '#585D71' }}>
          Descubre tus beneficios
        </Typography>
        <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
          <Card
            style={{
              padding: 0,
              display: 'grid',
              gridTemplateRows: '1fr max-content',
              marginBottom: '20px',
              height: '150px',
              borderRadius: '12px',
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${WomanDriverImg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundPositionY: '30%',
                backgroundSize: '110% auto',
                height: '100%',
                minHeight: '100%',
                borderRadius: '12px',
              }}
            />
            <Box sx={{ display: 'flex', gap: '17px', padding: '10px 26px', alignItems: 'center' }}>
              <CategoryIcon category="diamond" height="27px" />

              <Typography
                sx={{
                  fontFamily: 'Poppins-Regular',
                  color: '#5B5D71',
                  fontSize: '14px',
                }}
              >
                15% de descuento en tu plan de arriendo
              </Typography>
            </Box>
          </Card>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '15px',
            }}
          >
            <Card
              style={{
                padding: '13px',
              }}
            >
              <CategoryIcon category="gold" height="35px" />
              <Typography
                sx={{
                  textAlign: 'center',
                  marginTop: '12px',
                  marginBottom: '0',
                  fontFamily: 'Poppins-Regular',
                  color: '#5B5D71',
                  fontSize: '14px',
                }}
              >
                Categoría oro
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  marginTop: 0,
                  fontFamily: 'Poppins-SemiBold',
                  color: '#0057B8',
                  fontSize: '16px',
                }}
              >
                5% desc.
              </Typography>
            </Card>
            <Card
              style={{
                padding: '13px',
              }}
            >
              <CategoryIcon category="platinum" height="35px" />
              <Typography
                sx={{
                  textAlign: 'center',
                  marginTop: '12px',
                  marginBottom: '0',
                  fontFamily: 'Poppins-Regular',
                  color: '#5B5D71',
                  fontSize: '14px',
                }}
              >
                Categoría platino
              </Typography>
              <Typography
                sx={{
                  marginTop: 0,
                  fontFamily: 'Poppins-SemiBold',
                  color: '#0057B8',
                  fontSize: '16px',
                }}
              >
                10% desc.
              </Typography>
            </Card>
          </Box>
        </Box>
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            color: '#585D71',
          }}
        >
          *Los descuentos no son acumulables
        </Typography>
      </Box>
    </Box>
  );
};

GeneralProfile.propTypes = {
  setMessageError: PropTypes.func.isRequired,
  setOpenError: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func,
};

GeneralProfile.defaultProps = {
  setCurrentTab: () => {},
};

export default GeneralProfile;
